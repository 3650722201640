import { TYPES } from "../../types";

const initialState = {
  notif: false,
  notifType: "",
  notifMessage: "",
};

export const notifReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_NOTIF:
      return {
        ...initialState,
        notif: true,
        notifType: action?.payload?.notifType,
        notifMessage: action?.payload?.notifMessage,
      };
    case TYPES.REMOVE_NOTIF:
      return {
        ...initialState,
        notif: false,
        notifType: "",
        notifMessage: "",
      };
    default:
      return state;
  }
};
