const initialState = {
    message: "",
}

export const adminAuthReducers = (state = initialState, action) => {
    switch (action.type) {
        case "LOGIN_ADMIN":
            return {
                ...initialState,
                message: action.payload,
            }
            
        default:
            return state;
    }
}