const initialState = {
    burgerBtnOn: true
}

export const burgerBtnReducerOwner = (state = initialState, action) => {
    switch (action.type) {
        case "BURGER_ONOFF":
            return{
                ...initialState,
                burgerBtnOn: action.payload,
            } 
        default:
            return state;
    }
}