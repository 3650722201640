import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  palette: {
    primary: {
      main: "#0b3237",
    },
    secondary: {
      main: "#ff9800",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif", // Custom font family
  },
  // You can customize more aspects of the theme here
})

export default theme
