import { Backdrop, CircularProgress } from "@mui/material"
import React from "react"

const OverlayLoading = ({ loading }) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >
      <CircularProgress style={{ color: "0b3237" }} />
    </Backdrop>
  )
}

export default OverlayLoading
