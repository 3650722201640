import { TYPES } from "../../types";

const initialState = {
  isApiLoading: false,
};

export const apiLoadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.API_LOADING:
      return {
        ...initialState,
        isApiLoading: action.payload,
      };
    default:
      return state;
  }
};
