import React, { Suspense } from "react"
import { BrowserRouter } from "react-router-dom"
import "./App.css"
import OverlayLoading from "./components/owner/overlayLoading"
const RoutesPages = React.lazy(() => import("./routes/RoutesPages"))

const App = () => {
  return (
    <Suspense fallback={<OverlayLoading loading={true} />}>
      <BrowserRouter>
        <RoutesPages />
      </BrowserRouter>
    </Suspense>
  )
}

export default App
