import { TYPES } from "../../types";

const initialState = {
  isToken: false,
  loginError: false,
  branchOwned: [],
  isVerified: false,
  isVerifMessage: "",
  ownerId: null,
};

export const authOwnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        isToken: action?.payload.isToken,
        branchOwned: action?.payload.branchOwned,
        isVerified: action?.payload.isVerified,
      };

    case TYPES.LOGIN_FAILED:
      return {
        ...state,
        loginError: action.payload,
      };

    case TYPES.LOGIN_NOT_VERIF:
      return {
        ...state,
        isVerified: false,
        isVerifMessage: action?.payload?.isVerifMessage,
        ownerId: action?.payload?.ownerId,
      };

    case "CHECK_TOKEN":
      return {
        ...initialState,
        isToken: action.payload,
      };
    default:
      return state;
  }
};
