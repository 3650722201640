import { combineReducers } from "redux";
import { adminAuthReducers } from "./admin/reducers/adminAuthReducers";
import { authOwnerReducer } from "./owner/reducers/authOwnerReducer";
import { burgerBtnReducerOwner } from "./owner/reducers/burgerBtnReducerOwner";
import { apiLoadingReducer } from "./owner/reducers/apiLoadingReducer";
import { notifReducer } from "./owner/reducers/notifReducer";

const rootReducers = combineReducers({
  adminAuth: adminAuthReducers,
  authReducer: authOwnerReducer,
  apiLoading: apiLoadingReducer,
  burgerBtn: burgerBtnReducerOwner,
  notifReducer: notifReducer,
});

export default rootReducers;
